import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { makeSelectUser } from 'containers/UserInformation/selectors';
import { updateUser } from 'containers/UserInformation/actions';
import { OutlineLinkButton } from 'components/OutlineButton';
import { isSameAsDeviceTimezone } from 'utils/timezone';
import { tabletSize, phoneSize } from '../../global-styles';
import messages from './messages';

const BaseWrapper = styled.div`
  position: relative;
  height: 0;
  width: 100%;
`;

const SecondWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BannerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  z-index: 10;
  background-color: #1f285b;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 7.5%;
  padding-right: 7.5%;

  @media (max-width: ${tabletSize}) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: ${phoneSize}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const Message = styled.div`
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding-right: 12px;
  line-height: 1.1;

  @media (max-width: ${phoneSize}) {
    font-size: 14px;
  }
`;

const Button = styled(OutlineLinkButton)`
  box-shadow: none;
  margin: 0;
  padding: 10px 32px;
`;

function TimezoneUpdate(props) {
  const { user, updateUser } = props;
  const [isOpen, setIsOpen] = useState(false);

  const deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (user.get('id')) {
      if (!isSameAsDeviceTimezone(user.get('timezone') || deviceTimezone)) {
        updateUser({ timezone: deviceTimezone }, (response) => {
          if (response) {
            setIsOpen(true);
          }
        });
      }
    }
  }, [deviceTimezone, updateUser, user]);

  if (!isOpen) return <></>;

  return (
    <BaseWrapper>
      <SecondWrapper>
        <BannerWrapper>
          <Message>
            <FormattedMessage {...messages.updated} />
          </Message>
          <div>
            <Button as="button" tabIndex={0} onClick={() => setIsOpen(false)}>
              <FormattedMessage {...messages.close} />
            </Button>
          </div>
        </BannerWrapper>
      </SecondWrapper>
    </BaseWrapper>
  );
}

TimezoneUpdate.propTypes = {
  timezoneObject: PropTypes.instanceOf(Map).isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (data, callback) => dispatch(updateUser(data, callback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneUpdate);
