import { defineMessages } from 'react-intl';

export const scope = 'app.components.timezoneUpdate';

export default defineMessages({
  close: {
    defaultMessage: 'Close',
  },
  updated: {
    defaultMessage:
      "Your time zone has been automatically updated to your device's time zone.",
  },
});
