import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { getTimezoneLabel } from 'utils/timezone';
import { phoneSize } from '../../global-styles';
import messages from './messages';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 40px 0 0;

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: 600;

  @media (max-width: ${phoneSize}) {
    margin: 0 30px 30px 0;
  }
  .current-timezone {
    color: #0f2045;
    text-align: right;
  }

  .current-time {
    text-align: right;
  }

  .time-line {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    .current-time {
      color: rgba(15, 32, 69, 0.75);
      margin: 0 9px;
    }
  }
`;

function TimezoneSelect({ timezoneObject }) {
  const [currentTimeString, setCurrentTimeString] = useState('');
  const timezone = timezoneObject.get('currentTimezone');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimeString(moment().format('hh:mm A'));
    }, 1000);

    return () => clearInterval(interval);
  }, [currentTimeString]);

  return (
    <Wrapper>
      <div className="current-timezone">{getTimezoneLabel(timezone)}</div>
      <div>
        <div className="current-time">
          {<FormattedMessage {...messages.timeNow} />}: {currentTimeString}
        </div>
      </div>
    </Wrapper>
  );
}

TimezoneSelect.propTypes = {
  timezoneObject: PropTypes.instanceOf(Map).isRequired,
};

export default TimezoneSelect;
